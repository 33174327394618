import { BaseAPI } from "./api";
import { IConfiguration, IGetConfigurations, INetSuiteCredentials, ISaveConfigurationResponse } from "models";
import {INetSuiteCustomField} from "models/CustomField";
import {INetSuiteCustomFields} from "models/CustomFields";
import { saveInLocalStorage } from "utils";

export class ConfigurationService {
    api: BaseAPI;

    constructor(token: string) {
        this.api = new BaseAPI(process.env.REACT_APP_API_URL, token, false);
    }


    async getConfigurations(): Promise<IGetConfigurations> {
        const resp = await this.api.get("/v1/configurations/companies");
        const data: IGetConfigurations = resp?.success ? resp.data : null;
        if(resp?.success) {
            saveInLocalStorage(data);
        }
        return data;
    };

    async saveConfigurations(configuration: IConfiguration, id: string): Promise<ISaveConfigurationResponse> {
        const resp = await this.api.put("/v1/configurations/" + id, configuration);
        let expiredToken = false;
        if(!resp?.success) {
            const status = resp.data.response.status;
            const errorMessage = resp.data.response?.data;

            if(errorMessage !== undefined && status === 401 && errorMessage.includes("Token is expired")) {
                expiredToken = true;
            }
        }
        const response: ISaveConfigurationResponse = {
            success: resp?.success,
            expiredToken: expiredToken
        };
        return response;
    };

    async updateConfigurationStatus(successfulSyncConfiguration: boolean, id: string): Promise<boolean> {
        const resp = await this.api.post("/v1/configurations/" + id + "/status", {"active": successfulSyncConfiguration});
        return resp?.success;
    };

    async authenticateNetSuite(netSuiteCredentials: INetSuiteCredentials): Promise<boolean> {
        //calling the service for NetSuite authentication
        const resp = await this.api.post("/v1/configurations/authentication/ping", netSuiteCredentials);
        return !!resp?.success;
    };

    async checkConfiguration(configuration: IConfiguration, id: string): Promise<any> {
        //calling the service for NetSuite Test Sync
        return await this.api.post("/v1/configurations/" + id + "/sync/dryrun", configuration);
    };

    async getEmployeeCustomFields(configurationId: string): Promise<INetSuiteCustomField[]> {
        const resp = await this.api.get("/v1/configurations/custom-fields/employee/" + configurationId);
        return resp?.success ? resp.data : [];
    };

    async getVendorCustomFields(configurationId: string): Promise<INetSuiteCustomField[]> {
        const resp = await this.api.get("/v1/configurations/custom-fields/vendor/" + configurationId);
        return resp?.success ? resp.data : [];
    };

    async getExpenseCustomFields(configurationId: string): Promise<INetSuiteCustomField[]> {
        const resp = await this.api.get("/v1/configurations/custom-fields/expense/" + configurationId);
        return resp?.success ? resp.data : [];
    };

    async syncCustomFields(): Promise<INetSuiteCustomFields> {
        const resp = await this.api.post("/v1/configurations/custom-fields");
        return resp?.success ? resp.data : null;
    };

    async getFeatureFlag(flagKey: string): Promise<boolean> {
        const resp = await this.api.get("/v1/feature-flag?flagKey=" + flagKey);
        return resp?.success ? resp.data : false;
    };

    async getCountriesList(): Promise<Map<string, string>> {
        const resp = await this.api.get("/v1/configurations/countries");
        return resp?.success ? resp.data : [];
    };

}