import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from './APIResponse';
import { StatusCode } from 'enums';
import { lodash } from "utils";

export type axiosErrorType = any;
export type axiosDataType = any;

export class BaseAPI {
    _axios: AxiosInstance;

    constructor(baseURL?: string, token?: string, passCookies?: boolean, addParams?: Record<string, unknown>) {
        const axiosInitData: any = {};
        axiosInitData.baseURL = lodash.isEmpty(baseURL) ? '' : baseURL;
        //axiosInitData.withCredentials = isNullOrEmpty(passCookies) ? true : passCookies;

        if(!lodash.isEmpty(token)) {
            axiosInitData.headers = {
                Authorization: 'Bearer ' + token
            };
        }

        if(!lodash.isEmpty(addParams)) {
            if(lodash.isEmpty(axiosInitData.headers)) {
                axiosInitData.headers = {};
            }
            Object.assign(axiosInitData.headers, addParams);
        }

        if(Object.keys(axiosInitData).length === 0 && axiosInitData.constructor === Object) {
            this._axios = axios.create();
        } else {
            this._axios = axios.create(axiosInitData);
        }
    }

    async get(url: string): Promise<APIResponse> {
        try {
            const res = await this._axios.get(url);
            return this.apiResponse(true, res);
        } catch(error: axiosErrorType) {
            return this.handleError(error);
        }
    }

    async post(url: string, data?: axiosDataType): Promise<APIResponse> {
        try {
            let res;
            if(data) {
                res = await this._axios.post(url, data);
            } else {
                res = await this._axios.post(url);
            }
            return this.apiResponse(true, res);
        } catch(error: axiosErrorType) {
            return this.handleError(error);
        }
    }

    async put(url: string, data: axiosDataType): Promise<APIResponse> {
        try {
            const res = await this._axios.put(url, data);
            return this.apiResponse(true, res);
        } catch(error: axiosErrorType) {
            return this.handleError(error);
        }
    }

    async patch(url: string, data: axiosDataType): Promise<APIResponse> {
        try {
            const res = await this._axios.patch(url, data);
            return this.apiResponse(true, res);
        } catch(error: axiosErrorType) {
            return this.handleError(error);
        }
    }

    async delete(url: string): Promise<APIResponse> {
        try {
            const res = await this._axios.delete(url);
            return this.apiResponse(true, res);
        } catch(error: axiosErrorType) {
            return this.handleError(error);
        }
    }

    async reAuthRequest(): Promise<APIResponse> {
        throw new Error('Not implemented yet. The class extending the Base class needs to do so.');
    }

    setReAuthData(_resp: APIResponse, _error: axiosErrorType) {
        throw new Error('Not implemented yet. The class extending the Base class needs to do so.');
    }

    enableReAuth() {
        this._axios.interceptors.response.use(undefined, (error) => {
            if(error.config && error.response && error.response.status === StatusCode.Unauthorized) {
                return this.reAuthRequest().then((resp: APIResponse) => {
                    this.setReAuthData(resp, error);
                    return this._axios.request(error.config);
                });
            }
            return Promise.reject(error);
        });
    }

    private apiResponse(success: boolean, res: AxiosResponse): APIResponse {
        return {
            success: success,
            data: success ? res.data : res,
        };
    }

    private handleError(error: AxiosError): APIResponse {
        return {
            success: false,
            data: error
        };
    }
}
