import React, {ReactNode, useRef} from 'react'
import { Alert, Box, Button, Typography } from "@emburse/embark-core";
import { useTranslation } from "react-i18next";
import {AccountCredentialSettings, RouteLink} from "enums";
import { BottomNavBar, ViewContainer } from "components";
import { ConfigurationContext } from "providers";
import { ConfigurationService } from "services";
import { getConfigurationFromLocalStorage, getDefaultStringValue, isValuesExistsKeyMap, lodash } from "utils";
import { IConfiguration, INetsuiteDimensionResponse } from "models";
import {useLocation} from "react-router-dom";

export const Review = () => {
    const {t} = useTranslation();

    const {
        configurationId,
        companyId,
        token,
        featureFlags,
        syncTest,
        setSyncTest,
        displayTimeoutMessage,
        netSuiteCustomEmployeeFields,
        netSuiteCustomVendorFields,
        netSuiteCustomExpenseFields,
        setDisplayTimeoutMessage,
        netsuiteAuthentication,
        successfulSyncConfiguration,
        setSuccessfulSyncConfiguration,
        successfulValidConfiguration,
        setSuccessfulValidConfiguration,
        updatedConfiguration,
        setUpdatedConfiguration,
        errorSyncConfiguration,
        setErrorSyncConfiguration,
        warningsSyncConfiguration
    } = React.useContext(ConfigurationContext);
    const configurationService = new ConfigurationService(token);
    const timeOut = Number(process.env.REACT_APP_SYNC_TIMEOUT ?? 180);
    const [changeMade, setChangeMade] = React.useState<boolean>(false);

    const authenticationNetsuiteStatus = getDefaultStringValue(companyId + "_" + configurationId + "_" + AccountCredentialSettings.AUTHENTICATION_NETSUITE_STATUS);
    const enableCheckYourConfigurationButton = authenticationNetsuiteStatus !== undefined && authenticationNetsuiteStatus === 'successful'  && !netsuiteAuthentication.inProgress;
    const checkYourConfigurationButtonRef = useRef<any>();

    const location = useLocation();
    const locationState = (location.state as {clickCheckYourConfigurationButton? : boolean});
    const clickCheckYourConfigurationButton = locationState?.clickCheckYourConfigurationButton;

    React.useEffect(() => {
        const localStorageConfiguration = getConfigurationFromLocalStorage(companyId, configurationId);

        const changeMade = !lodash.isEqual(updatedConfiguration, localStorageConfiguration);
        setChangeMade(changeMade);
        if(enableCheckYourConfigurationButton && clickCheckYourConfigurationButton){
            checkYourConfigurationButtonRef.current.click();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const addWarningMessage = (element: INetsuiteDimensionResponse, dimension: string) => {
        if(!lodash.isNil(element)) {
            if(!element.success && element.message.length > 0 && element.message[0].type === 'ERROR' && dimension !== "vendors") {
                warningsSyncConfiguration.push(t('syncWarningMessage', {
                    dimension: dimension,
                    message: element?.message[0]?.message
                }));
            } else if(element.success && element.total === 0) {
                warningsSyncConfiguration.push(t('syncWarningEmptyDataMessage', {
                    dimension: dimension
                }));
            }
        }
    }

    const checkCustomFields = (netSuiteConfiguration: IConfiguration) => {

        let warnings = false;

        if(featureFlags.customFieldsFeatureEnabled) {
            const netsuiteExpenseCustomFieldsToImport = netSuiteConfiguration.expense?.importExpenseSettings?.netsuiteCustomFieldsToImport;
            if(isValuesExistsKeyMap(netsuiteExpenseCustomFieldsToImport, netSuiteCustomExpenseFields)) {
                warningsSyncConfiguration.push(t('syncWarningCustomFieldsMessage', {
                    dimension: 'Expense'
                }));
                warnings = true;
            }

            const netsuiteEmployeeCustomFieldsToImport = netSuiteConfiguration.user?.employee?.netsuiteCustomFieldsToImport;
            if(isValuesExistsKeyMap(netsuiteEmployeeCustomFieldsToImport, netSuiteCustomEmployeeFields)) {
                warningsSyncConfiguration.push(t('syncWarningCustomFieldsMessage', {
                    dimension: 'Employee'
                }));
                warnings = true;
            }

            const netsuiteVendorCustomFieldsToImport = netSuiteConfiguration.user?.vendor?.netsuiteCustomFieldsToImport;
            if(isValuesExistsKeyMap(netsuiteVendorCustomFieldsToImport, netSuiteCustomVendorFields)) {
                warningsSyncConfiguration.push(t('syncWarningCustomFieldsMessage', {
                    dimension: 'Vendor'
                }));
                warnings = true;
            }
        }
        return warnings;
    }

    const checkMandatoryFields = (netSuiteConfiguration: IConfiguration) => {
        let mandatoryFieldMissing: string[] = [];
        if(netSuiteConfiguration.user?.syncVendors && lodash.isEmpty(netSuiteConfiguration.user?.vendor?.filterVendorByCustomFieldId)) {
            mandatoryFieldMissing.push(`"${t('filterVendorByCustomFieldId')}"`);
        }

        if(netSuiteConfiguration.customerAndProject?.customerSettings?.filterByCustomFieldEnabled && lodash.isEmpty(netSuiteConfiguration.customerAndProject?.customerSettings?.filterByCustomField)) {
            mandatoryFieldMissing.push(`"${t('filterByCustomField')}"`);
        }

        if(netSuiteConfiguration.customerAndProject?.customerSettings?.ignoreByCustomFieldEnabled && lodash.isEmpty(netSuiteConfiguration.customerAndProject?.customerSettings?.ignoreByCustomField)) {
            mandatoryFieldMissing.push(`"${t('ignoreByCustomField')}"`);
        }

        if(netSuiteConfiguration.customerAndProject?.projectSettings?.filterByCustomFieldEnabled && lodash.isEmpty(netSuiteConfiguration.customerAndProject?.projectSettings?.filterByCustomField)) {
            mandatoryFieldMissing.push(`"${t('filterByCustomField')}"`);
        }

        if(netSuiteConfiguration.customerAndProject?.projectSettings?.ignoreByCustomFieldEnabled && lodash.isEmpty(netSuiteConfiguration.customerAndProject?.projectSettings?.ignoreByCustomField)) {
            mandatoryFieldMissing.push(`"${t('ignoreByCustomField')}"`);
        }

        return mandatoryFieldMissing;
    }

    const checkConfiguration = () => {
        setSyncTest({inProgress: true});
        setSuccessfulSyncConfiguration(false);
        setSuccessfulValidConfiguration(false);
        setDisplayTimeoutMessage(false);
        setErrorSyncConfiguration("");

        while(warningsSyncConfiguration.length > 0) {
            warningsSyncConfiguration.pop();
        }

        const localStorageConfiguration = getConfigurationFromLocalStorage(companyId, configurationId);

        setUpdatedConfiguration(localStorageConfiguration);

        const configurationErrors = checkMandatoryFields(localStorageConfiguration);

        const customFieldsWarnings = checkCustomFields(localStorageConfiguration);

        if(lodash.isEmpty(configurationErrors) && !customFieldsWarnings) {

            const timer = setTimeout(function() {
                setDisplayTimeoutMessage(true);
                setSuccessfulSyncConfiguration(false);
            }, timeOut * 1000);

            configurationService.checkConfiguration(localStorageConfiguration, configurationId).then(response => {
                setSyncTest({inProgress: false});
                setDisplayTimeoutMessage(false);
                clearTimeout(timer);
                setChangeMade(false);

                let successfulSyncConfiguration;
                if(response?.success) {
                    successfulSyncConfiguration = response?.data.success;

                    if(response.data.success) {
                        addWarningMessage(response?.data.syncClassDryRunResponse, "classes");
                        addWarningMessage(response?.data.syncCustomerDryRunResponse, "customers");
                        addWarningMessage(response?.data.syncDepartmentDryRunResponse, "departments");
                        addWarningMessage(response?.data.syncEmployeeDryRunResponse, "employees");
                        addWarningMessage(response?.data.syncExpenseCategoryDryRunResponse, "expense categories");
                        addWarningMessage(response?.data.syncLocationDryRunResponse, "locations");
                        addWarningMessage(response?.data.syncProjectDryRunResponse, "projects");
                        addWarningMessage(response?.data.syncSubsidiaryDryRunResponse, "subsidiaries");
                        addWarningMessage(response?.data.syncVendorDryRunResponse, "vendors");

                    } else if(response.data.code === 'NETSUITE_AUTHENTICATION_EXCEPTION') {
                        setErrorSyncConfiguration(t('syncErrorNetsuiteAuthorizationFailed'));

                    } else if(response.data.code === 'INSUFFICIENT_PERMISSION') {
                        setErrorSyncConfiguration(t('syncErrorInsufficientPermission'));

                    } else if(response.data.code === 'SESSION_TIMED_OUT' || response.data.code === 'UNEXPECTED_ERROR' || response.data.code === 'USER_ERROR') {
                        setErrorSyncConfiguration(t('syncError'));

                    } else if(!response.data.syncVendorDryRunResponse?.success && response.data.syncVendorDryRunResponse?.message[0].code === 'INVALID_SEARCH_FIELD_OBJ') {
                        setErrorSyncConfiguration(t('syncErrorFilterVendorByCustomFieldId', {message: response.data.syncVendorDryRunResponse.message[0].message}));
                    }

                } else {
                    successfulSyncConfiguration = false;
                    setErrorSyncConfiguration(t('syncError'));
                }
                setSuccessfulSyncConfiguration(successfulSyncConfiguration);
            });

        } else {
            setSyncTest({inProgress: false});
            setSuccessfulSyncConfiguration(false);

            if(!lodash.isEmpty(configurationErrors)) {
                let fieldNames: string = "";
                const listSize = configurationErrors.length;

                configurationErrors.forEach((element: string, index) => {
                    if(index === 0) {
                        fieldNames = fieldNames.concat(element);
                    } else if(index < listSize - 1) {
                        fieldNames = fieldNames.concat(', ' + element);
                    } else {
                        fieldNames = fieldNames.concat(' and ' + element);
                    }
                });

                setErrorSyncConfiguration(t('mandatoryFieldErrorMessage', {
                    fieldName: fieldNames
                }));
            }
        }
    }

    const displayWarningMessages = () => {
        let displayWarningMessage: ReactNode;

        if(!lodash.isEmpty(warningsSyncConfiguration)) {
            warningsSyncConfiguration.forEach((element: string, index) => {
                displayWarningMessage = (<>
                        {displayWarningMessage}
                        <Alert severity="warning" data-qa={`warning-configuration-sync-message-${index}`}
                               verticalSpacingSize={1} verticalSpacingPosition="top">{element}</Alert>
                    </>
                )
            });
            return displayWarningMessage;
        }
    }

    return <>
        <ViewContainer data-qa="review-page">
            <>
                <Typography verticalSpacingSize={1} variant="h4" data-qa="title-review">{t('review')}</Typography>
                <Typography data-qa="sub-title-review">{t('subTitleReview')}</Typography>

                {displayTimeoutMessage && <Alert severity="info" verticalSpacingSize={6} verticalSpacingPosition="top"
                                                 data-qa="timeout-configuration-sync-message">{t('timeoutConfigurationSyncMessage')}</Alert>}

                {!changeMade && successfulValidConfiguration && lodash.isEmpty(warningsSyncConfiguration) &&
                    <Alert severity="success" verticalSpacingSize={6} verticalSpacingPosition="top"
                           data-qa="successful-saved-configuration-message">{t('successfulSavedValidConfigurationMessage')}</Alert>}

                {!changeMade && successfulSyncConfiguration && !successfulValidConfiguration && lodash.isEmpty(warningsSyncConfiguration) &&
                    <Alert severity="success" verticalSpacingSize={6} verticalSpacingPosition="top"
                           data-qa="successful-sync-configuration-message">{t('successfulTestSyncConfigurationMessage')}</Alert>}

                {!lodash.isEmpty(errorSyncConfiguration) &&
                    <Alert severity="error" verticalSpacingSize={6} verticalSpacingPosition="top"
                           data-qa="error-configuration-sync-message">{errorSyncConfiguration}</Alert>}

                <Box verticalSpacingSize={5} verticalSpacingPosition="top">
                    {displayWarningMessages()}
                </Box>

                <Box verticalSpacingSize={6} verticalSpacingPosition="top">
                    <Button id="checkConfiguration"
                            data-qa="check-configuration-button"
                            isLoading={syncTest.inProgress}
                            disabled={!enableCheckYourConfigurationButton}
                            onClick={checkConfiguration}
                            ref={checkYourConfigurationButtonRef}>
                        {t('checkYourConfiguration')}
                    </Button>
                </Box>

            </>
        </ViewContainer>

        <BottomNavBar
            prevDataQA="back-to-expenses-button-navigation"
            prevText={t('expenses')}
            prevRoutes={RouteLink.Expenses}/>
    </>
}